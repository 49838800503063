import React from 'react'
import Img from 'gatsby-image'
import { Link } from 'gatsby'
import { useMediaQuery } from 'react-responsive'

const GridLayoutItem = (props) => {
  const {
    image,
    title,
    cookingTime = 'XX',
    prepTime = 'YY',
    backsideDescription = 'Pad Thai is the most famous Thai dish.',
    category,
    gridRowSpan,
    gridColumnSpan,
    minHeight,
    slug,
  } = props
  const isTabletOrDesktop = useMediaQuery({ query: '(min-width: 768px)' })
  return (
    <div
      className="grid-item one"
      style={{
        ...(isTabletOrDesktop &&
          gridRowSpan && { gridRow: `span ${gridRowSpan}` }),
        ...(isTabletOrDesktop &&
          gridColumnSpan && { gridColumn: `span ${gridColumnSpan}` }),
        ...(isTabletOrDesktop && minHeight && { minHeight: `${minHeight}px` }),
      }}
    >
      {isTabletOrDesktop && backsideDescription ? (
        <div className="flip-card">
          <div className="flip-card-inner">
            <div className="flip-card-front">
              <Img fluid={image} style={{ height: '100%' }} />
            </div>
            {/*<a href={slug} className="flip-card-back">*/}
            <Link
              to={`/${slug}`}
              state={{ fromBlogList: 'food' }}
              className="flip-card-back"
            >
              <div className="flip-card-content">
                <h2>{title}</h2>
                <small className="fc-cooking-times">
                  <i>
                    <p>
                      Prep {prepTime} min, Cook {cookingTime} min{' '}
                    </p>
                  </i>
                </small>
                <p>
                  <i>{backsideDescription}</i>
                </p>
                <div className="start-cooking">
                  Start cooking{' '}
                  <span role="img" aria-label="Chef Emoji">
                    👨‍🍳
                  </span>
                </div>
              </div>
            </Link>
            <div className="food-image-caption">
              <span>{category}</span>
              <span>{title}</span>
            </div>
          </div>
        </div>
      ) : (
        <Link
          to={`/${slug}`}
          state={{ fromBlogList: 'food' }}
          className="flip-card-inner not-flippable"
        >
          <div className="flip-card-front">
            <Img fluid={image} style={{ height: '100%' }} />
          </div>
          {/*<a href={slug} className="flip-card-back">*/}
          {/*</a>*/}
          <div className="food-image-caption">
            <span>{category}</span>
            <span>{title}</span>
          </div>
        </Link>
      )}
    </div>
  )
}

const GridLayout = (props) => {
  const {
    children,
    handleInputChange,
    selectedCategory,
    categoryButtons,
    amountFiltered,
  } = props
  return (
    <>
      <div className="grid-container">
        <div className="container category-selectors">
          <div className="category-buttons-container">
            {categoryButtons.map((category) => {
              return (
                <button
                  className={`selector-item ${
                    category === selectedCategory && 'active-item'
                  }`}
                  name={category}
                  onClick={handleInputChange}
                  key={`category-${category}`}
                >
                  {category}
                </button>
              )
            })}
          </div>
          <h2>Näytetään: {amountFiltered} reseptiä</h2>
        </div>
        <div className="container grid-layout">{children}</div>
      </div>
    </>
  )
}

export { GridLayout, GridLayoutItem }
