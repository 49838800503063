import { graphql } from 'gatsby'
import React, { useEffect, useState } from 'react'
import Layout from '../components/Layout'
import { GridLayout, GridLayoutItem } from '../components/templates/GridLayout'

const Foodblog = (props) => {
  //const { edges } = data.allMdx
  const { data } = props
  const allPosts = data.allMdx.edges
  const { categories } = data

  const emptyQuery = ''
  const [selectedCategory, setSelectedCategory] = useState('All')
  const [state, setState] = useState({
    filteredData: [],
    query: emptyQuery,
  })

  const dataFiltering = (query) => {
    const { data } = props
    const posts = data.allMdx.edges || []
    const filteredData = posts.filter((post) => {
      const { category } = post.node.frontmatter
      return category.toLowerCase().includes(query.toLowerCase())
    })
    //console.log(filteredData.length)
    //console.log(allPosts.length)
    setState({
      query,
      filteredData,
    })
  }
  const handleInputChange = (event) => {
    event.target.focus()
    //console.log(event.target.name)
    //const query = event.target.value
    const query = event.target.name
    dataFiltering(query)
    setSelectedCategory(event.target.name)
    sessionStorage.setItem('selected_cuisine', event.target.name)
  }
  const { filteredData, query } = state
  const amountFiltered = () => {
    if (selectedCategory === 'All') {
      return allPosts.length
    } else {
      return filteredData.length
    }
  }
  const hasSearchResults = filteredData && query !== emptyQuery
  const posts =
    selectedCategory !== 'All' && hasSearchResults ? filteredData : allPosts

  const categoryButtons = categories.edges
    .map(({ node }) => {
      return node.name.charAt(0).toUpperCase() + node.name.slice(1)
    })
    .filter((categoryName) => {
      return allPosts.find(({ node }) =>
        node.frontmatter.category.includes(categoryName)
      )
    })
    .sort((a, b) => {
      if (a === 'Misc') {
        return 1
      } else if (b === 'Misc') {
        return -1
      }
      return a > b
    })
  categoryButtons.unshift('All')

  useEffect(() => {
    const storedSelectedCuisine = sessionStorage.getItem('selected_cuisine')
    if (storedSelectedCuisine) {
      setSelectedCategory(storedSelectedCuisine)
      dataFiltering(storedSelectedCuisine)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Layout
      metaTitle={'Foodblog | Pyry Palosaari'}
      metaDescription={'Lorem Ipsum Generator'}
      smallHeader
    >
      <GridLayout
        handleInputChange={handleInputChange}
        selectedCategory={selectedCategory}
        categoryButtons={categoryButtons}
        amountFiltered={amountFiltered()}
      >
        {posts.map(({ node }) => {
          const {
            colSpan,
            rowSpan,
            smallImage,
            mediumImage,
            title,
            cookingTime,
            prepTime,
            backsideDescription,
            category,
            imageMinHeight,
          } = node.frontmatter
          const cardImageSources = [
            smallImage && smallImage.childImageSharp.fluid,
            {
              ...(mediumImage && mediumImage.childImageSharp.fluid),
              media: `(min-width: 480px)`,
            },
          ]
          return (
            <GridLayoutItem
              gridColumnSpan={colSpan}
              gridRowSpan={rowSpan}
              image={smallImage !== null && cardImageSources}
              title={title}
              cookingTime={cookingTime}
              prepTime={prepTime}
              backsideDescription={backsideDescription}
              category={category}
              slug={node.slug}
              minHeight={imageMinHeight}
              key={node.slug}
            />
          )
        })}
      </GridLayout>
    </Layout>
  )
}

export const pageQuery = graphql`
  query foodBlogList {
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        fileAbsolutePath: { regex: "/blog/food/" }
        frontmatter: { hide_post: { ne: true } }
      }
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            title
            category
            rowSpan
            colSpan
            cookingTime
            prepTime
            backsideDescription
            last_updated(formatString: "MMMM Do, YYYY")
            date(formatString: "MMMM Do, YYYY")
            author
            imageMinHeight
            smallImage: image {
              childImageSharp {
                fluid(maxWidth: 200) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            mediumImage: image {
              childImageSharp {
                fluid(maxWidth: 400) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          fields {
            readingTime {
              text
            }
          }
          slug
        }
      }
    }
    categories: allDirectory(
      filter: { absolutePath: { regex: "/blog/food/" } }
    ) {
      edges {
        node {
          name
        }
      }
    }
  }
`

export default Foodblog
